<template>
  <div>
    <page-header :title="title"></page-header>
    <form @submit.prevent="gravar">
      <div class="modal-body">
        <div class="form-horizontal">
          <vue-element-loading
            :active="isActive"
            spinner="bar-fade-scale"
            color="#428BCA"
          ></vue-element-loading>
          <input-form-text
            textInput="Nome"
            :required="true"
            v-model="form.nome"
            :value="form.nome"
          ></input-form-text>
          <button @click="getCarregar">Carregar</button>
          <br />
          <br />
          <div class="form-group">
            <div class="col-sm-6">
              <JsonEditor
                :options="{
                  confirmText: 'confirm',
                  cancelText: 'cancel'
                }"
                :objData="form.descricao"
                v-model="form.descricao"
              >
              </JsonEditor>
            </div>
            <div class="col-sm-6">
              <div slot="content">
                <pre>
                    <code>{{form.descricao}}</code>
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer-button :disabled="isActive"></footer-button>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import VueElementLoading from 'vue-element-loading'
import PageHeader from '@/components/PageHeader'
import InputFormText from '@/components/form/InputForm'
import FooterButton from '@/components/form/FooterButton'
import Permission from '@/mixins/Permission'
import Services from '@/utils/services'
import { sucesso, erro } from '@/components/Message'

export default {
  components: {
    PageHeader,
    InputFormText,
    FooterButton,
    VueElementLoading
  },
  mixins: [Permission],
  computed: {
    ...mapGetters(['user'])
  },
  data() {
    return {
      title: 'Dados',
      form: {
        nome: '',
        descricao: '[]'
      },
      isActive: false
    }
  },
  methods: {
    ...mapActions(['getLogsTotal']),
    async gravar() {
      this.isActive = true
      const body = this.form
      if (this.$route.params.id) {
        await Services.put(`/tipagem/${this.$route.params.id}`, body)
          .then(() => {
            sucesso('Atualizado...')
            this.$router.push('/sistema/tipagem')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      } else {
        await Services.post('/tipagem', body)
          .then(() => {
            sucesso('Atualizado...')
            this.$router.push('/sistema/tipagem')
          })
          .catch(response => {
            console.log('error: ' + response)
            erro()
          })
      }
      this.isActive = false
    },
    async getCarregar() {
      const dados = await Services.get('/miss/teste')
      this.form.descricao = dados
    },
    async getDados() {
      const dados = await Services.get(
        `/tipagem/${this.$route.params.id}`
      ).catch(response => {
        console.log('error: ' + response)
        return (this.isActive = false)
      })
      this.form.nome = dados.nome
      this.form.descricao = dados.descricao
      this.isActive = false
    },
    verifPermissao() {
      if (this.$route.params.id) {
        if (!this.ACTION.e) {
          this.$router.push('/erro')
        }
      } else {
        if (!this.ACTION.c) {
          this.$router.push('/erro')
        }
      }
    }
  },
  mounted() {
    this.verifPermissao()
    if (this.$route.params.id) {
      this.isActive = true
      this.getDados()
    }
  }
}
</script>

<style lang="stylus" scoped></style>
